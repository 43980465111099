<template>
  <div>
    <div class="flex-row d-tips" v-if="isShowBtn">
      <div class="d-tip-title">温馨提示:</div>
      <div>
        <div class="d-pd8">1、标记为“必备材料”的附件，必须上传。</div>
        <div class="d-pd8">
          2、请按照附件要求，每条记录以一个文件形式上传，如果多个文件则先合并为一个文件（建议DOC或PDF或ZIP格式）后再上传。
        </div>
        <div class="d-pd8">
          3、视频文件不超过2GB，其他类型文件不超过50M，格式为
          <span class="tip-red">JPG、PNG、DOC、DOCX、WPS、PDF、XLS、XLSX、ZIP </span>
          格式的文件。
        </div>
        <div class="d-pd8">
          4、对于大于一页的单个附件，如审计报告等，建议采用PDF格式。
        </div>
        <div class="d-pd8">
          5、附件材料的复印件或者原件，以扫描或者拍照的形式，确保足够清晰、否则将影响单位信息的审核，直至专项资金的审批拨付。
        </div>
      </div>
    </div>
    <div class="ranking-container">
      <div class="ranking-top">
        <div class="ranking-table ranking-title">
          <div class="ranking-table-item item-r1">序号</div>
          <div class="ranking-table-item item-r3">附件名称</div>
          <div class="ranking-table-item item-r4">附件描述</div>
          <div class="ranking-table-item item-r5">查看样表</div>
		  <div v-if="isBack == '0'" class="ranking-table-item item-r5">审核意见</div>
          <div v-if="isShowBtn" class="ranking-table-item item-r6">操作</div>

        </div>
      </div>
      <div class="ranking-content">
        <div class="ranking-table" v-for="(item, index) in processList" :key="index">
          <div class="ranking-table-item item-r1">
            {{ index + 1 }}
          </div>
          <div class="ranking-table-item item-r3 text-left">
            <p class="max-one-line">
              <span v-if="item.sfby == 1 || item.isneed == 1" class="bichuan">*</span>
              {{ item.clname || item.clmc }}
			  <!-- <span :class="item.shzt==0 ? 'red' : ''">{{item.shzt == 0 ? '审核意见:'+item.shyj : ''}}</span> -->
            </p>


          </div>
          <div class="ranking-table-item item-r4">暂无</div>
          <div class="ranking-table-item item-r5">暂无样表</div>
		  <div v-if="isBack == '0'" class="ranking-table-item item-r5" :class="item.shzt==0 ? 'red' : item.shzt == 1 ? 'green' : ''">{{item.shzt == 1 ? '审核通过' : item.shyj || '' }}</div>
          <div class="ranking-table-item item-r6" v-if="isShowBtn">
            <el-button
              size="small"
              :type="item.files && item.files.length > 0 ? 'success' : 'primary'"
              @click="openDialog(index)"
            >
              {{ item.files && item.files.length  > 0 ? `已上传 (${item.files.length}个)` : '上传' }}
            </el-button>
            <span v-if="item.files && item.files.length>0"></span>

          </div>

        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dgVisible" width="1400px">
      <div class="flex-row-between  enclosure-container ">
        <!-- left -->
        <!-- 附件名称 -->
        <div class="flex-column-between  enclosure-container-left">
          <p class="dialog-title colorBule">上传材料</p>
          <!-- 附件列表 -->
          <div class="dialog-middle enclosure-list-border">
            <div class="flex-row line-height40" style="border-bottom: 1px dotted #aaaaaa;">
              <p>序号</p>
              <p style="margin-left: 20px">材料名称</p>
            </div>
            <div class="enclosure-list-container">
              <div v-for="(item, index) in processList" :key="index"
                   class="flex-row-between pointer line-height40 enclosure-list"
                   :class=" currentMaterialItemIndex == index ? ' action-list' : '' "
                   @click="itemCLClick(index)"
              >
                <p>{{`${index + 1}`}}<span class="bichuan"
                                           :class=" currentMaterialItemIndex == index ? ' action-list' : '' "> {{ item.sfby == 1 || item.isneed == 1 ? '*' : '' }}</span><span
                  class="line-limit-length" style="max-width:300px">{{ `${item.clname || item.clmc}` }} </span>
                </p>
                <p></p>
                <p><span style="color: red;">{{ item.files.length === 0 ? '' : `[${item.files.length}]` }}</span>
                </p>
              </div>
            </div>
          </div>
          <!-- 下面 -->
          <div class="dialog-bottom" style="margin-top: 10px">
            <!-- 按钮 -->
            <el-button
              :disabled="currentMaterialItemIndex == 0"
              icon="el-icon-arrow-left"
              @click="prePage"
              type="primary"
            >上一份
            </el-button>
            <el-button
              :disabled="currentMaterialItemIndex == processList.length - 1"
              @click="nextPage"
              type="primary"
            >下一份<i class="el-icon-arrow-right el-icon&#45;&#45;right"></i>
            </el-button>
          </div>
        </div>
        <!-- rigth -->
        <div class="flex-row-between enclosure-container-right">
          <!-- 材料列表 -->
          <div class="flex-column-between choose-not">
            <div class="flex-row-between shared-material">
              <div class="dialog-title">历史材料</div>
              <!-- 搜索+按钮 -->
              <div class="serach" style="">
                <input
                  class="my-input"
                  v-model="keyWord"
                  placeholder="请输入附件关键字"
                />
                <div @click="queryData" class="my-btn" plain></div>
              </div>
            </div>
            <div class="dialog-middle enclosure-list-border">
              <!-- 搜索+按钮 -->
              <!-- <div class="flex-row flex-start">
                <el-input
                  style=" margin-right: 10px"
                  v-model="keyWord"
                  placeholder="请输入附件关键字"
                ></el-input>
                <el-button @click="queryData" type="primary" plain>查询</el-button>
              </div> -->
              <!-- 列表 -->
              <div class="">
                <el-table
                  :data="tableData"
                  height="370" size="mini" ref="multipleTable"
                  @select='onTableSelect'
                  @selection-change="handleSelectionChange"
                  stripe>
                  <el-table-column
                    type="selection"
                    width="44">
                  </el-table-column>
                  <el-table-column
                    label="序号"
                    type="index"
                    width="50"
                    align="center"
                    style="padding: 0"
                  ></el-table-column>
                  <el-table-column label="附件名称" min-width="200">
                    <template slot-scope="scope">
                      <span class="max-one-line">{{ scope.row.filename }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <!-- 下面 -->
            <div class="dialog-bottom mt-10">
              <!-- <el-pagination
                class="mt-10"
                background
                :total="total"
                :page-size="pageSize"
                @current-change="changePageNum"
              ></el-pagination> -->
              <el-button @click="chooseFile" type="primary">本地上传</el-button>
            </div>
          </div>
          <!-- 已选 -->
          <div class="flex-column-between choose">
            <p class="dialog-title">已选文件</p>
            <div class="dialog-middle  enclosure-list-border">
              <el-table
                class=" "
                :data="processList[currentMaterialItemIndex]? processList[currentMaterialItemIndex].files : [] "
                height="370"
                size="mini">
                <el-table-column
                  label="序号"
                  type="index"
                  width="60"
                  align="center"
                ></el-table-column>
                <el-table-column label="附件名称" min-width="100">
                  <template slot-scope="scope">
                    <span class="max-one-line">{{ scope.row.filename }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" min-width="50">
                  <template slot-scope="scope">
                    <el-button type="danger" @click="removeFile(scope.$index)" icon="el-icon-delete"
                               circle
                               size="mini"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 下面 -->
            <div class="dialog-bottom mt-10">
              <!-- <el-pagination
                class="mt-10"
                background
                :total="total"
                :page-size="pageSize"
                @current-change="changePageNum"
              ></el-pagination> -->
              <el-button type="primary" @click="dgVisible = false">确 定</el-button>
            </div>
          </div>
        </div>
      </div>

      <input
        ref="fileInput"
        v-on:change="uploadFile"
        type="file"
        name="file"
        style="display: none"
      />
    </el-dialog>
  </div>
</template>
<script>
  import httpApi from "../../utils/http";
  import {mapGetters} from "vuex";

  export default {
    props: {
      itemList: {
        type: Array,
        default: function () {
          return [];
        },
      },
	  isBack: {
	    type: String,
	    default: function () {
	      return '1';
	    },
	  },
      itemWorkList: {
        type: Array,
        default: function () {
          return [
            [
              {
                content: "土地使用权出让合同签订申请表",
                title: "材料名称",
              },
              {
                content: "请上传pdf格式文件",
                title: "材料要求",
              },
              {
                content: "官网下载",
                title: "来源渠道",
              },
              {
                content: "政务网办事指南下载",
                title: "来源渠道说明",
              },
              {
                content: "土地使用权出让合同签订申请表",
                title: "填报须知",
              },
              {
                content: "是",
                title: "是否必须",
              },
              {
                content: "无",
                title: "备注",
              },
            ],
          ];
        },
      },
      isShowBtn: {
        type: Boolean,
        default: () => {
          return true;
        },
      },
    },
    mounted() {
      console.log("材料列表++++++++++++++++=", this.itemList)
      console.log("材料列表++++++++++++++++=", this.itemWorkList)
      // this.getDataList();
    },
    data() {
      return {
        fileViewVisible: false,
        fileData: null,
        fileViewgKey: "",
        uploadUrl: this.$httpApi.baseUrl + "/dev-api/fileManageApi/common/upload",
        uploadHeader: {
          appid: httpApi.appid,
          appkey: httpApi.appkey,
          token: httpApi.token,
        },
        /* 存放文件信息 */
        wordInfo: [],
        //控制对话框显示
        dgVisible: false,
        currentRow: null,
        //对话框左侧的材料列表
        tableData: [],
        // 对话框右侧的材料列表
        rightList: [],
        keyWord: "", //搜索关键字
        total: 0,
        currentPageNum: 1,
        currentMaterialItem: null,

        currentMaterialItemIndex: -1,
        pageSize: 8,
      };
    },
    computed: {
      processList() {
        let itemList
        if (this.itemList.length > 0) {
          //初始化处理,赋值files字段为空,避免选择文件回显出现问题
          itemList = this.itemList;
          for (let i = 0; i < itemList.length; i++) {
            let item = itemList[i];
            if (!item.files) {
              itemList[i].files = []
            }
          }
        } else {
          //初始化处理,赋值files字段为空,避免选择文件回显出现问题
          itemList = this.itemWorkList;
          for (let i = 0; i < itemList.length; i++) {
            let item = itemList[i];
            if (!item.files) {
              itemList[i].files = []
            }
          }
        }
        return itemList;
      },
      dialogTitle() {
        let item = this.processList[this.currentMaterialItemIndex];
        if (!item) {
          return "材料"
        }
        if (item.clname == undefined && item.clmc == undefined) {
          return "";
        }
        if (item.clname != undefined && item.clname != null && item.clname != "") {
          return item.clname;
        }
        return item.clmc;
      },
      ...mapGetters({
        userInfo: "getUserInfo",
      }),
      // 计算需要监听的数据
      dateRange() {
        const {currentMaterialItemIndex,} = this
        return {
          currentMaterialItemIndex,
        }
      }
    },
    watch: {
      dateRange() {
        // console.log("观察>>>>>", rows, this.processList[this.currentMaterialItemIndex])
        let mList = this.tableData
        let rightLists = this.processList[this.currentMaterialItemIndex].files
        console.log("右边样式>>>", rightLists)
        let tab = this.tableData
        let margin = []
        for (const item of rightLists) {
          for (let i = 0; i < mList.length; i++) {
            if (item.fileid === mList[i].fileid) {
              margin.push(tab[i])
            }
          }
        }
        console.log("匹配到的>>>", margin)
        if (margin.length > 0) {
          this.toggleSelection(margin)
        }
      }
    },
    methods: {
      /**
       * 按钮取消判断
       */
      onTableSelect(rows, row) {
        console.log("接收>>>", rows, row)
        let selected = rows.length && rows.indexOf(row) !== -1
        console.log(selected)  // true就是选中，0或者false是取消选中
        if (selected == 0) {
          let index = this.currentMaterialItemIndex;
          let files = this.processList[index].files
          //
          //已选择文件列表中是否包含
          for (let i = 0; i < files.length; i++) {
            let item = files[i]
            if (item.fileid == row.fileid) {
              files.splice(i, 1)
              console.log("删除数组>>>", files)
            }
          }
        }
      },
      /**
       * 材料点击切换
       */
      itemCLClick(e) {
        this.currentMaterialItemIndex = e;
        //清除表格复选框状态
        this.toggleSelection()
      },
      nextPage() {
        this.currentMaterialItemIndex++
        //清除表格复选框状态
        this.toggleSelection()
      },
      prePage() {
        this.currentMaterialItemIndex--
        //清除表格复选框状态
        this.toggleSelection()
      },
      //查询数据
      queryData() {
        let keyword = this.keyWord;
        let param = {
          pageNum: 1,
          pageSize: 20,
          filename: keyword,
        };
		/**
         * 20210426
         * TODO
         * 目前改用扫码登录后，没有loginId了
         * 是否可以在上传和查询的时候，都统一用别的字段代替？
         */
        if (this.$store.getters.getUserInfo) {
          let loginId = this.userInfo.userId;
          this.$httpApi.get("/dev-api/fileRecord/list/" + loginId, param).then((res) => {
            console.log("查询的附件列表:" + JSON.stringify(res));
            if (res.code == 200) {
              this.total = res.total;
              this.tableData = res.rows;
            }
          }).catch((err) => {});
        }
      },
      //请求数据
      getDataList() {
        let pageNum = this.currentPageNum;
        let keyword = this.keyWord;
        let param = {
          pageNum: pageNum,
          pageSize: 20,
          filename: keyword,
        };
        /**
         * 20210426
         * TODO
         * 目前改用扫码登录后，没有loginId了
         * 是否可以在上传和查询的时候，都统一用别的字段代替？
         */
        // console.log('userInfo:', this.userInfo)
        let loginId = this.userInfo.userId

        this.$httpApi.get("/dev-api/fileRecord/list/" + loginId, param).then((res) => {
          console.log("附件列表" + JSON.stringify(res));
          if (res.code == 200) {
            this.total = res.total;
            this.tableData = res.rows;
          }
        });
      },
      changePageNum(current) {
        //current是当前页数
        this.currentPageNum = current;
        this.getDataList();
      },
      handleSelectionChange(rows) {
        console.log("接收数据11111>>>", rows, this.tableData)
        this.rightList = rows;
        let items = rows
        for (let i = 0; i < items.length; i++) {
          let val = items[i]
          let index = this.currentMaterialItemIndex;
          let file = {
            status: "1", // 文件状态是否可用 0作废 1可用
            fileid: val.fileid, // 文件ID
            filename: val.filename, // 文件名称
            name: val.fileName, // 文件名称
            filepath: val.viewUrl, // 文件路径
            ly: "", // 来源
          };
          this.addToSelectFiles(index, file)
        }
      },
      handleClick(val, col, event) {
        //复选框回显
        this.toggleSelection(val)

        let index = this.currentMaterialItemIndex;

        let file = {
          status: "1", // 文件状态是否可用 0作废 1可用
          fileid: val.fileid, // 文件ID
          filename: val.filename, // 文件名称
          name: val.fileName, // 文件名称
          filepath: val.viewUrl, // 文件路径
          ly: "", // 来源
        };
        this.addToSelectFiles(index, file)

      },
      //切换某行的checkbox状态
      toggleSelection(rows) {
        if (rows) {
          if (rows.length > 0) {
            rows.forEach(row => {
              this.$refs.multipleTable.toggleRowSelection(row);
            });
          } else {
            this.$refs.multipleTable.toggleRowSelection(rows);
          }
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },

      /**
       * 上传按钮点击事件
       */
      openDialog(index) {
        //显示对话框
        this.dgVisible = true;
        //保存点开时的材料item
        this.currentMaterialItem = this.processList[index];
        this.currentMaterialItemIndex = index;
        this.getDataList();
      },
      addToSelectFiles(index, file) {
        let files = this.processList[index].files
        //已选择文件列表中是否包含
        let flag = false
        for (let i = 0; i < files.length; i++) {
          let item = files[i]
          if (item.fileid == file.fileid) {
            flag = true
            // files.splice(i, 1)
            // console.log("删除数组>>>", files)
          }
        }
        if (!flag) {
          this.processList[index].files.push(file);
        }

      },
      // 本地上传
      chooseFile() {
        //调用文件选择input的点击事件,弹出文件选择
        let fileInputEl = this.$refs.fileInput;
        fileInputEl.click();
      },
      uploadFile() {
        // console.log(this.$refs);
        let fileInputEl = this.$refs.fileInput;
        if (fileInputEl.files.length > 0) {
          let file = fileInputEl.files[0];

          httpApi.uploadFile(file).then((response) => {
            if (response.code == 200) {
              this.$message({
                type: "success",
                message: "文件上传成功",
              });

              let index = this.currentMaterialItemIndex;

              let file = {
                status: "1", // 文件状态是否可用 0作废 1可用
                fileid: response.fileId, // 文件ID
                filename: response.fileName, // 文件名称
                name: response.fileName, // 文件名称
                filepath: response.url, // 文件路径
                ly: "", // 来源
              };

              this.addToSelectFiles(index, file)
              this.exposeInfo();

              //保存附件记录
              this.saveRecord(response);
            } else {
              this.$message({
                type: "error",
                message: "文件上传失败",
              });
            }
          });
        }

        /**/
      },
      //添加附件记录
      saveRecord(res) {
        let url = "/dev-api/fileRecord/saveRecords/";

        let clid = this.currentMaterialItem.clid;
        if (clid == null) {
          clid = "";
        }
        let userInfo = this.userInfo
		/**
         * 20210426
         * TODO
         * 目前改用扫码登录后，没有loginId了
         * 是否可以在上传和查询的时候，都统一用别的字段代替？
         */
        let recordList = [
          {
            clid: clid,
            fileid: res.fileId,
            filename: res.fileName,
            loginId: userInfo.userId,
            // loginId: userInfo.loginId,
            createBy: userInfo.name,
            viewUrl: res.url,
          },
        ];

        this.$httpApi.post(url, recordList).then((res) => {
          if (res.code == 200) {
            console.log("附件记录保存成功");
            el.dgVisible = true;
          }
        });
      },
      removeFile(index) {
        let item = this.tableData[index]

        this.$confirm('确认移除' + item.filename, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let currentMaterialItemIndex = this.currentMaterialItemIndex;
          //移除数据
          this.processList[currentMaterialItemIndex].files.splice(currentMaterialItemIndex, 1);
          console.log(this.processList[currentMaterialItemIndex].files.length);
        })
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定要移除 ${file.name}？`);
      },
      // 删除文件
      handleRemove(file, files, index) {
        console.log("remove", file, files, index);
        let tempFiles = this.processList[index].files || [];
        let res = [];
        for (const i in tempFiles) {
          if (Object.hasOwnProperty.call(tempFiles, i)) {
            const item = tempFiles[i];
            if (item.fileid !== file.fileid) {
              res.push(item);
            }
          }
        }
        this.processList[index].files = res;
        console.log("files:", this.processList);
      },
      //文件上传验证.
      //文件上传验证.
      getFileType(fileName) {
        let pdfTypes = ["pdf", "PDF"];
        let imageTypes = [
          "bmp",
          "jpg",
          "JPG",
          "jpeg",
          "JPEG",
          "png",
          "PNG",
          "tif",
          "gif",
          "GIF",
        ];
        let wordTypes = ["doc", "docx", "xls", "xlsx"];
        let fileContentType = fileName.match(/^(.*)(\.)(.{1,8})$/)[3];
        console.log("filecontent", fileContentType);

        let isPdf = false;
        let isImage = false;
        let isWord = false;

        for (var i in pdfTypes) {
          if (fileContentType.toLowerCase() == pdfTypes[i].toLowerCase()) {
            isPdf = true;
          }
        }
        // 先去判断是否是pdf 再去判断是否是图片 再去判断是否是word
        if (!isPdf) {
          for (var i in imageTypes) {
            if (fileContentType.toLowerCase() == imageTypes[i].toLowerCase()) {
              isImage = true;
            }
          }
        }
        // 前面这2个都是false才检查这个
        if (!isPdf && !isImage) {
          for (var i in wordTypes) {
            if (fileContentType.toLowerCase() == wordTypes[i].toLowerCase()) {
              isWord = true;
            }
          }
        }
        // 如果是PDF的则用PDF打开，如果是Image的则用image显示，否则就是不能预览的类型
        return {isPdf: isPdf, isImage: isImage, isWord: isWord};
      },
      handlePreview(file) {
        console.log("开始预览", file);
        let item = {};
        if (file.response) {
          item = {
            fileName: file.response.fileName,
            fileUrl: file.response.url,
            fileId: file.response.fileId,
          };
        } else {
          item = {
            fileName: file.filename,
            fileUrl: file.filepath,
            fileId: file.fileid,
          };
        }
        let fileTypeResult = this.getFileType(item.fileName);
        console.log("文件类型:", fileTypeResult);
        if (fileTypeResult.isPdf || fileTypeResult.isImage) {
          const routeData = this.$router.resolve({
            name: "previewPdf",
            query: {...item},
          });
          console.log(item, {...item});
          window.open(routeData.href, "_blank");
        } else {
          window.location.href = item.fileUrl;
          // this.$message({
          //   type: "error",
          //   message: "此文件不支持预览",
          // });
        }
      },
      checkFiles() {
      },
      removeFiles() {
      },
      /* 暴露信息 */
      exposeInfo() {
        /*  应该返回的格式为
                {
                  "clid": "cscl001",
                  "clbm": "csbh001",
                  "clmc": "测试材料001",
                  "isneed": "1",
                  "orinum": "0",
                  "copynum": "2",
                  "submittype": "1",
                  "status": "1",
                  "certid": "证照ID",
                  "ly": "3",
                  "sfrq": "0",
                  "files": [
                    {
                      "status": "0",
                      "fileid": "csfileid001",
                      "filename": "ceshifu附件1.png",
                      "filepath": "filepath1",
                      "ly": "3"
                    },
                    {
                      "status": "1",
                      "fileid": "csfileid002",
                      "filename": "ceshifu附件2.png",
                      "filepath": "filepath2",
                      "ly": "3"
                    }
                  ]
                },
          */
        // console.log("this.processList:", this.processList);
        this.$emit("uploadFileSuccess", this.processList);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/styles/com/theme.scss";

  .d-tips {
    padding: 20px 20px;
    font-size: 13px;
    color: rgba(64, 64, 64, 1);
    justify-content: flex-start;
    align-items: flex-start !important;

    .d-tip-title {
      font-size: 15px;
      color: #000;
      margin-right: 20px;
    }

    .d-pd8 {
      padding-bottom: 8px;
    }

    .tip-red {
      color: rgba(233, 81, 81, 1);
    }
  }

  .ranking-container {
    width: 100%;
    height: 100%;
    color: #333;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    // background: chocolate;
    position: relative;

    .ranking-top {
      width: 100%;
      color: #909399;
    }

    .ranking-title {
      background: $bc-lightGrey;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 14px;
    }

    .ranking-content {
      width: 100%;
      font-size: 14px;
    }
  }

  .bichuan {
    color: red;
  }

  .ranking-table {
    width: 100%;
    // height: 4vh;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    // background: cornflowerblue;
    &:hover {
      // height: 70px;
      // font-size: $font-size18;s
      font-weight: bold;
      background: rgba($color-primary, 0.1);
    }

    .ranking-table-item {
      // border: 0.1px solid rgba($color: #247eff, $alpha: 0.2);
      border-bottom: 1px solid rgba(#333, 0.1);
      height: 60px;
      line-height: 60px;
      text-align: center;
    }

    .text-left {
      text-align: left !important;
    }

    .item-growthRate {
      color: rgba(45, 129, 255, 1);
    }

    .item-r1 {
      flex: 1;
    }

    .item-r2 {
      flex: 1;
    }

    .item-r3 {
      flex: 6;
    }

    .item-r4 {
      flex: 2;
    }

    .item-r5 {
      flex: 1.5;
    }

    .item-r6 {
      flex: 2.5;
    }
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  // 材料容器
  .enclosure-container {
    position: relative;
    height: 500px;
    background: #fff;

    .enclosure-container-left,
    .choose-not,
    .choose {
      padding: 4px;
    }

    // 左边附件名称
    .enclosure-container-left {
      flex: 4;
      min-width: 220px;
      height: 100%;
      // border: 1px solid #333;
    }

    .arrow-blue {
    }

    // 右边
    .enclosure-container-right {
      flex: 7;
      height: 100%;
      // border: 1px solid #333;
      .choose-not {
        flex: 1;
        height: 100%;
        // border: 1px solid rgb(33, 214, 78);
      }

      .choose {
        flex: 1;
        height: 100%;
        // border: 1px solid rgb(36, 54, 209);
      }
    }

    .enclosure-list-container {
      width: 100%;
      height: 330px;
      overflow: hidden;
      overflow-y: scroll;
      white-space: nowrap;
    }

    .enclosure-list {
      width: 100%;
      border-bottom: 1px dotted #aaaaaa;
      padding: 0 10px;
    }

    // 附件边框样式
    .enclosure-list-border {
      width: 100%;
      height: 360px;
      border: 1px solid #edeeef;
      // background: red;
      padding: 10px;
    }

    // 上部标题
    .dialog-title {
      width: 120px;
      line-height: 40px;
      align-self: flex-start;
      text-align: center;
      color: #000;
      font-size: 14px;
      font-weight: 700;
      font-family: Microsoft YaHei;
      border: 1px solid #edeeef;
      border-bottom: 1px solid #fff;
      background: #fff;
      margin-bottom: -1px;
      z-index: 999;
    }

    // 中间内容
    .dialog-middle {
      width: 100%;
      flex: 9;
      // border: 1px solid #333;
    }

    // 底部内容
    .dialog-bottom {
      width: 100%;
      flex: 1;
      // border: 1px solid rgb(199, 25, 190);
      text-align: center;
    }

    // 被选中样式
    .action-list {
      color: #fff;
      height: 43px;
      background-color: #2d81ff;
    }

    .colorBule {
      color: $color-primary;
    }

    // 附件名称
    .line-height40 {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      text-align: center;
    }

    // 共享材料
    .shared-material {
      width: 100%;
      // background: red;
      .serach {
        width: 280px;
        height: 100%;
        // height: 30px;
        // background-color:#2d81ff;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: flex-start;

        .my-input {
          width: 250px;
          height: 30px;
          padding: 0 4px;
          background-color: #f6f6f6;
        }

        .my-btn {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: url("../../assets/images/serach_my_icon.png") center /50% 50% no-repeat #2d81ff;
        }
      }
    }
  }
	.red {
		color: red;
		float: right;
	}
	.green {
		color: green;
	}
</style>

<style lang="scss">
  .el-table__header-wrapper .el-checkbox { //找到表头那一行，然后把里面的复选框隐藏掉
    display: none
  }

  .enclosure-container {

  }
</style>
